import { TMetadataActionTypes, IMetadataState } from '../../interfaces/reducers/metadata'
import {
  METADATA_AUTH_FAILED,
  METADATA_INVALID_SESSION,
  METADATA_LOADED,
  METADATA_START_LOAD,
  METADATA_UPDATE,
} from '../../types/metadata'
import { getUUID, getProvider } from '../../api/url.helpers'

const initialState: IMetadataState = {
  metadataInit: false,
  authenticated: false,
  error: null,
  token: '',
  uuid: getUUID(),
  userId: null,
  userEmail: null,
  pin: null,
  locale: 'en',
  interface: 0,
  featureToggles: {},
  trackWiFiCrypto: false,
  provider: getProvider(),
  DEBUG: false,
  REMOTE_DEBUGGER_SRC: '',
  MIXPANEL_ID: '',
  GTM_DEVICE_ID: '',
}

function Metadata(state: IMetadataState = initialState, action: TMetadataActionTypes): IMetadataState {
  switch (action.type) {
    case METADATA_START_LOAD:
      state.metadataInit = true
      state.authenticated = false

      return { ...state, metadataInit: true, authenticated: false }

    case METADATA_LOADED:
      if (!action.data) {
        return state
      }

      state = action.data
      state.metadataInit = true
      state.error = null

      return state

    case METADATA_UPDATE:
      if (!action.data) {
        return state
      }

      return { ...state, locale: action.data.locale, interface: action.data.interface, error: null }

    case METADATA_AUTH_FAILED:
      return { ...state, authenticated: false }

    case METADATA_INVALID_SESSION:
      return { ...state, pin: null, authenticated: false, error: 'invalid_session' }
  }

  return state
}

export default Metadata
