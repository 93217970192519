import React, { Fragment } from 'react'
import StartEndHour from '../../../../../../components/date/startEndHour'
import { IPerson } from '../../../../../../../interfaces/events'
import { getEventTimeStatus } from '../../../../../../../api/event.helpers'
import { formatTime, getTime, Trans } from '@joan/joan-core'

type TProps = {
  organizer?: IPerson
  showDate?: boolean
  summary: string
  start: string
  end: string
  ampm: boolean
}

const getMeetingDate = (start: string): string => {
  return formatTime(getTime(start), 'ddd, MMM D')
}

const getTimeComponent = (start: string): JSX.Element => {
  const status = getEventTimeStatus(start)

  if (status === 'tomorrow') {
    return <span><Trans>Tomorrow</Trans>&nbsp;</span>
  }

  if (status.length === 0) {
    return <span>{getMeetingDate(start)},&nbsp;</span>
  }

  return <Fragment />
}

function Meeting({ organizer, ampm, start, end, summary, showDate = false }: TProps): JSX.Element {
  return (
    <Fragment>
      <div className="meeting-title">
        <h4>{summary}</h4>
      </div>
      {organizer && <div className="meeting-organizer">{organizer.displayName || organizer.email}</div>}
      <div className="meeting-time">
        {showDate ? getTimeComponent(start) : null}
        <StartEndHour startTime={start} endTime={end} ampm={ampm} />
      </div>
    </Fragment>
  )
}

export default Meeting
