import React from 'react'

type TProps = {
  children: React.ReactNode
}

function CenterPanel(props: TProps): JSX.Element {
  return (
    <div className="center-panel">
      <div className="flex-parent">{props.children}</div>
    </div>
  )
}

export default CenterPanel
