import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from './components/login'
import Display from './components/display'
import { Provider } from 'react-redux'
import getStore from '../../context/store'
import ContentWrapper from '../components/contentWrapper'

import './main.scss'

const JoanInRoom = (): JSX.Element => {
  return (
    <Provider store={getStore()}>
      <ContentWrapper catalog="display" type="main" className="flex-parent">
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/" component={Display} />
          </Switch>
        </Router>
      </ContentWrapper>
    </Provider>
  )
}

export default JoanInRoom
