import React, { Fragment } from 'react'
import { IEvent, IAttendees } from '../../../../../../interfaces/events'
import Icon from '../../../../../components/svg/icon'
import { ReactComponent as PersonSVG } from '../../../../../../assets/icons/person.svg'
import { ReactComponent as PeopleSVG } from '../../../../../../assets/icons/people_full.svg'
import { ReactComponent as CameraSVG } from '../../../../../../assets/icons/camera.svg'
import { Trans } from '@joan/joan-core'

type TProps = {
  event?: IEvent
}

const emptyEvent = (): JSX.Element => {
  return (
    <div className="empty-event">
      <h2>
        <Trans>The room is currently free</Trans>
      </h2>
    </div>
  )
}

const content = (event: IEvent): JSX.Element => {
  return (
    <Fragment>
      <div className="title">
        <h2>{event.summary}</h2>
      </div>
      {event.organizer ? (
        <div className="organizer">
          <Icon>
            <PersonSVG />
          </Icon>
          <span className="text-item">{event.organizer.displayName || event.organizer.email}</span>
        </div>
      ) : null}
      {event.attendees && event.attendees.length > 0 ? (
        <div className="attendees">
          <Icon>
            <PeopleSVG />
          </Icon>
          <span className="text-item">
            {event.attendees.map((attendee: IAttendees, index) => (
              <Fragment key={index}>
                <span className="attendee">{attendee.name || attendee.mail}</span>
                <br />
              </Fragment>
            ))}
          </span>
        </div>
      ) : null}
      {event.videoUrl && (
        <div className="video-url">
          <Icon>
            <CameraSVG />
          </Icon>
          <span className="text-item">
            <a href={event.videoUrl} target="_blank" rel="noopener noreferrer">
              {event.videoUrl}
            </a>
          </span>
        </div>
      )}
    </Fragment>
  )
}

function LeftPanel({ event }: TProps): JSX.Element {
  return <div className="left-panel">{!event ? emptyEvent() : content(event)}</div>
}

export default LeftPanel
