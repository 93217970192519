import { Dispatch } from 'redux'
import { IStoreState } from '../../interfaces/store'
import { ROOMS_LOADED, ROOMS_PAYMENT_REQUIRED } from '../../types/rooms'
import { IRoomsLoaded, IRoomsState, IRoomsPaymentRequired } from '../../interfaces/reducers/rooms'
import metadataActions from './metadata'

import { api } from '../../api/fetch'
import { LOGIN_DELAY, ROOM_DELAY } from '../../api/env.helpers'

let interval: number | undefined

const roomsLoaded = (data: IRoomsState): IRoomsLoaded => {
  return {
    type: ROOMS_LOADED,
    data,
  }
}

const roomsPaymentRequired = (): IRoomsPaymentRequired => {
  return {
    type: ROOMS_PAYMENT_REQUIRED,
  }
}

// Try to fetch data until user is authenticated.
const fetchRooms = (dispatch: Dispatch, uuid: string): Promise<any> => {
  return api
    .get('/api/2.0/device/' + uuid + '/rooms/')
    .then((data: IRoomsState) => {
      dispatch(
        metadataActions.metadataUpdate({
          locale: data.locale,
          interface: data.interface,
        }),
      )

      dispatch(roomsLoaded(data))

      return data
    })
    .catch((error: Response) => {
      clearInterval(interval)

      if (error && error.status === 402) {
        dispatch(roomsPaymentRequired())
      } else {
        dispatch(metadataActions.metadataAuthFailed())
      }
    })
}

const intervalDispatch = (cb: Function, delay: number = ROOM_DELAY): void => {
  clearInterval(interval)
  interval = window.setInterval(cb, delay)
}

const roomsActions = {
  getRooms(): Function {
    return (dispatch: Dispatch, getState: () => IStoreState): void => {
      const metadata = getState().metadata

      // If room select is true we check for room selection every 10s. This is used
      // when user is pairing new device. When room is connected switch to normal
      // interval
      intervalDispatch(() => {
        fetchRooms(dispatch, metadata.uuid).then((data?: IRoomsState) => {
          if (data && !data.roomSelect) {
            intervalDispatch(() => fetchRooms(dispatch, metadata.uuid))
          }
        })
      }, LOGIN_DELAY)

      fetchRooms(dispatch, metadata.uuid)
    }
  },
}

export default roomsActions
