import { Fetch, IFetchParams, IFetchQuery, TRequestData } from '../interfaces/api/fetch'
import { FETCH_DELAY, FETCH_RETRY, APP_HOST } from './env.helpers'

const reloadCodes = [402, 429]
let timeout: number | undefined

type TResolve = (response: Response) => void | Promise<void>
type TCatch = (error: Response) => void | Promise<void>

class ApiFetch implements Fetch {
  maxRetries = FETCH_RETRY
  delay = FETCH_DELAY

  public get(url: string, data?: TRequestData, headers?: HeadersInit, params: IFetchParams = {}): Promise<any> {
    return this.fetch(url, 'GET', data, headers, params)
  }

  public post(url: string, data?: TRequestData, headers?: HeadersInit, params: IFetchParams = {}): Promise<any> {
    return this.fetch(url, 'POST', data, headers, params)
  }

  public delete(url: string, data?: TRequestData, headers?: HeadersInit, params: IFetchParams = {}): Promise<any> {
    return this.fetch(url, 'DELETE', data, headers, params)
  }

  private fetch(
    url: string,
    method: string,
    data?: TRequestData,
    headers?: HeadersInit,
    params: IFetchParams = {},
  ): Promise<any> {
    const delay = params.delay || this.delay
    let maxRetries = params.maxRetries || this.maxRetries

    clearTimeout(timeout)

    const resolveFetch = (resolve: (value?: any) => void): TResolve => {
      return (response: Response): void | Promise<void> => {
        if (response.ok) {
          return resolve(response.json())
        }

        throw response
      }
    }

    const resolveCatch = (resolve: (value?: any) => void, reject: (value?: any) => void): TCatch => {
      return (error: Response): void | Promise<void> => {
        console.error('fetch error exception: ' + error)

        if (error.status >= 400 && !reloadCodes.includes(error.status)) {
          return reject(error)
        }

        if (--maxRetries && maxRetries > 0) {
          timeout = window.setTimeout(() => {
            params.maxRetries = maxRetries - 1

            this.fetch(url, method, data, headers, params)
              .then(resolveFetch(resolve))
              .catch(resolveCatch(resolve, reject))
          }, delay)
        }

        return reject(error)
      }
    }

    return new Promise<any>((resolve, reject) => {
      fetch(APP_HOST + url, {
        method: method,
        headers: ApiFetch.getHeaders(headers),
        body: ApiFetch.getData(data),
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
      })
        .then(resolveFetch(resolve))
        .catch(resolveCatch(resolve, reject))
    })
  }

  private static getHeaders(headers?: HeadersInit): HeadersInit {
    if (!headers) {
      headers = {
        'Content-Type': 'application/json',
      }
    }

    return headers
  }

  private static getData(data?: BodyInit | IFetchQuery): BodyInit | undefined {
    if (!data) {
      return data
    }

    if (!(data instanceof FormData)) {
      data = JSON.stringify(data)
    }

    return data
  }
}

export const api = new ApiFetch()
