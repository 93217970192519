import React, { CSSProperties } from 'react'
import { getTime } from '@joan/joan-core'

type TProps = {
  startTime: string
  endTime: string
}

type TState = {
  progress: number
}

class ProgressBar extends React.PureComponent<TProps, TState> {
  private interval = 0

  constructor(props: TProps) {
    super(props)

    this.state = { progress: 0 }
  }

  public componentDidMount(): void {
    this.interval = window.setInterval((): void => {
      const startTime = getTime(this.props.startTime).unix()
      const endTime = getTime(this.props.endTime).unix()

      const allTime = endTime - startTime
      const nowTime = getTime(new Date().toISOString()).unix()
      const spentTime = nowTime - startTime

      this.setState({
        progress: (spentTime / allTime) * 100,
      })
    }, 1000)
  }

  public componentWillUnmount(): void {
    clearInterval(this.interval)
  }

  public dotProgressStyle(): CSSProperties {
    if (!this.isOngoingEvent()) {
      return {
        display: 'none',
      }
    }

    return {
      left: `${this.state.progress - 2}%`,
    }
  }

  private isOngoingEvent(): boolean {
    return this.state.progress > 0 || this.state.progress < 100
  }

  public render(): JSX.Element {
    const className = 'progress-bar ' + (this.isOngoingEvent() ? 'ongoing' : 'vacant')

    return (
      <div className={className}>
        <div className="progress-dot" style={this.dotProgressStyle()} />
      </div>
    )
  }
}

export default ProgressBar
