import React from 'react'
import { Trans } from '@joan/joan-core'

type TProps = {
  pin: string
}

function Pin(props: TProps): JSX.Element {
  return (
    <div id="pin_msg">
      <p>
        <Trans>To connect Joan to your calendar, please enter the following PIN number into the Joan Portal:</Trans>
      </p>
      <h1 className="boxed">{props.pin}</h1>
      <p>
        <Trans>To edit Joan’s calendar settings and functionality, visit the Joan Portal at portal.getjoan.com</Trans>
      </p>
    </div>
  )
}

export default Pin
