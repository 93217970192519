import { TLocale } from '@joan/joan-core/dist/interfaces/Locale.interface'
import { setLocale } from '@joan/joan-core'

export function updateLocale(locale: TLocale): void {
  let localeFix = locale as string

  if (String(locale).toLowerCase() === 'en-us') {
    localeFix = 'en-gb'
  }

  if (locale) {
    localeFix = locale.toLowerCase()
  }

  setLocale(localeFix as TLocale)
}
