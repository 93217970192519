import { IEvent } from '../interfaces/events'
import { getTime, getWeekDayFromTime } from '@joan/joan-core'

const getTimeNow = () => {
  return getTime(new Date().toISOString())
}

export const getCurrentEvent = (events: IEvent[]): IEvent | undefined => {
  // Events should be sorted. Pop the first event check
  // if it's active
  if (events.length === 0) {
    return undefined
  }

  const event = events[0]

  const currentTime = getTimeNow()
  const startTime = getTime(event.start)
  const endTime = getTime(event.end)

  const startTimeLimit = startTime.isBefore(currentTime) || startTime.isSame(currentTime)
  const endTimeLimit = endTime.isAfter(currentTime) || endTime.isSame(currentTime)

  if (startTimeLimit && endTimeLimit) {
    return events.shift()
  }

  return undefined
}

export const isEventToday = (date: string): boolean => {
  return getTime(new Date().toISOString()).isSame(getTime(date), 'day')
}

export const isEventTomorrow = (date: string): boolean => {
  return getWeekDayFromTime(date) - getWeekDayFromTime(getTimeNow().toISOString()) === 1
}

export const getEventTimeStatus = (date: string): string => {
  if (isEventToday(date)) {
    return 'today'
  }

  if (isEventTomorrow(date)) {
    return 'tomorrow'
  }

  return ''
}
