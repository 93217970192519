import { applyMiddleware, combineReducers, compose, createStore, Middleware, Store, StoreEnhancer } from 'redux'
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from './middleware/logger'
import metadata from './reducers/metadata'
import rooms from './reducers/rooms'

export default function getStore(middlewares: Middleware[] = []): Store {
  middlewares = [loggerMiddleware, thunkMiddleware, ...middlewares]

  const middlewareEnhancer = applyMiddleware(...middlewares)
  const composedEnhancers: StoreEnhancer = compose(...[middlewareEnhancer])

  return createStore(combineReducers({ metadata: metadata, rooms: rooms }), undefined, composedEnhancers)
}
