import React from 'react'
import { formatTime, getTime, Trans } from '@joan/joan-core'
import StartEndHour from '../../../../../../components/date/startEndHour'
import { IEvent } from '../../../../../../../interfaces/events'

type TProps = {
  events: IEvent[]
  ampm: boolean
}

function getAmpmClassName(className: string, ampm: boolean): string {
  if (ampm) {
    return className + ' ampm'
  }

  return className
}

function Events({ events, ampm }: TProps): JSX.Element {
  let currentDate = ''

  return (
    <div className="events">
      {events.map(
        (event, index): JSX.Element => {
          const startTime = getTime(event.start)
          const comparator = startTime.year() + '-' + startTime.month() + '-' + startTime.day()
          const isChanged = currentDate !== comparator

          if (isChanged) {
            currentDate = comparator
          }

          return (
            <div key={index} className="event">
              {isChanged && (
                <div className="header">
                  <div className="timetable-title">{index === 0 && <Trans>Timetable</Trans>}</div>
                  <div className="datetime">{formatTime(startTime, 'ddd, MMM D, YYYY')}</div>
                </div>
              )}
              <div className="event-table">
                <div className={getAmpmClassName('event-time', ampm)}>
                  <StartEndHour startTime={event.start} endTime={event.end} ampm={ampm} />
                </div>
                <div className={getAmpmClassName('event-info', ampm)}>
                  <div className="event-name">{event.summary}</div>
                  <div className="event-organizer">{event.organizer.displayName || event.organizer.email}</div>
                </div>
              </div>
            </div>
          )
        },
      )}
    </div>
  )
}

export default Events
