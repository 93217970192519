import React, { Fragment } from 'react'
import { IEvent } from '../../../../../../../interfaces/events'
import { Trans } from '@joan/joan-core'

import Meeting from './meeting'
import Events from './events'

import './timetable.scss'

type TProps = {
  events: IEvent[]
  ampm: boolean
}

function Timetable({ ampm, events }: TProps): JSX.Element {
  return (
    <div className="timetable">
      {events.length === 0 && (
        <div className="no-events-info">
          <p>
            <Trans>No meetings scheduled for today.</Trans>
          </p>
        </div>
      )}

      {events.length > 0 && (
        <Fragment>
          <div className="next-meeting">
            <Meeting
              summary={events[0].summary}
              start={events[0].start}
              end={events[0].end}
              ampm={ampm}
              organizer={events[0].organizer}
            />
          </div>
          <Events events={events} ampm={ampm} />
        </Fragment>
      )}
    </div>
  )
}

export default Timetable
