import { Dispatch } from 'redux'
import {
  METADATA_LOADED,
  METADATA_START_LOAD,
  METADATA_UPDATE,
  METADATA_AUTH_FAILED,
  METADATA_INVALID_SESSION,
} from '../../types/metadata'
import roomsActions from './rooms'
import {
  IMetadataActionLoaded,
  IMetadataActionStartLoad,
  IMetadataGeneric,
  IMetadataState,
  IMetadataUpdate,
  IMetadataAuthFailed,
  IMetadataInvalidSession,
} from '../../interfaces/reducers/metadata'
import { IStoreState } from '../../interfaces/store'

import { api } from '../../api/fetch'
import { LOGIN_DELAY } from '../../api/env.helpers'

const metadataLoaded = (data: IMetadataState): IMetadataActionLoaded => {
  return {
    type: METADATA_LOADED,
    data,
  }
}

const metadataLoading = (): IMetadataActionStartLoad => {
  return {
    type: METADATA_START_LOAD,
  }
}

const metadataUpdate = (data: IMetadataGeneric): IMetadataUpdate => {
  return {
    type: METADATA_UPDATE,
    data: data,
  }
}

const metadataAuthFailed = (): IMetadataAuthFailed => {
  return {
    type: METADATA_AUTH_FAILED,
  }
}

const metadataInvalidSession = (): IMetadataInvalidSession => {
  return {
    type: METADATA_INVALID_SESSION,
  }
}

let timeout: number | undefined

// Try to fetch data until user is authenticated.
const fetchMetadata = (dispatch: Dispatch<any>, state: IStoreState): Promise<any> => {
  const retryDispatch = (): void => {
    dispatch(metadataAuthFailed())
    timeout = window.setTimeout(() => fetchMetadata(dispatch, state), LOGIN_DELAY)
  }

  const uuid = state.metadata.uuid
  const queryParams = new URLSearchParams()
  queryParams.set('type', '-1')
  queryParams.set('interface', '9')
  if (state.metadata.provider) {
    queryParams.set('provider', state.metadata.provider)
  }

  return api
    .get(`/api/2.0/device/${uuid}/login/?${queryParams}`)
    .then((data: IMetadataState) => {
      if (!data || !data.authenticated) {
        retryDispatch()
      } else {
        // When login endpoint returns authenticated flag we always call rooms endpoint.
        // Because of this we moved fetching of rooms from component to action. This will
        // ensure that when login is requested getRooms will be dispatched properly.
        dispatch(roomsActions.getRooms())
      }

      dispatch(metadataLoaded(data))
    })
    .catch((error: Response) => {
      if (error && error.status === 403) {
        dispatch(metadataInvalidSession())
      }

      retryDispatch()
    })
}

const metadataActions = {
  login(): Function {
    return (dispatch: Dispatch, getState: () => IStoreState): void => {
      dispatch(metadataLoading())

      clearTimeout(timeout)
      fetchMetadata(dispatch, getState())
    }
  },
  metadataUpdate,
  metadataAuthFailed,
  metadataInvalidSession,
}

export default metadataActions
