import React, { CSSProperties } from 'react'
import JoanHeadSvg from '../../assets/img/Joan_icon_white.svg'

const style: CSSProperties = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  width: '25%',
  height: '25%',
}

const Loading = (): JSX.Element => {
  return (
    <div className="joan-loading" style={style}>
      <img src={JoanHeadSvg} alt="" />
    </div>
  )
}

export default Loading
