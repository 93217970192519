import { MiddlewareAPI } from 'redux'

export default (store: MiddlewareAPI) => (next: any) => (action: any): any => {
  console.group(action.type)
  console.info('dispatching', action)

  const result = next(action)

  console.log('next state', store.getState())
  console.groupEnd()

  return result
}
