import React from 'react'
import { IEvent } from '../../../../../interfaces/events'

import './body.scss'
import LeftPanel from './panels/leftPanel'
import CenterPanel from './panels/centerPanel'
import RightPanel from './panels/rightPanel'
import CurrentEvent from './panels/center/currentEvent'
import Timetable from './panels/center/timetable'
import { getCurrentEvent } from '../../../../../api/event.helpers'

type TProps = {
  events: IEvent[]
  ampm: boolean
}

function DisplayBody({ ampm, events }: TProps): JSX.Element {
  const renderEvents = [...events]
  const event = getCurrentEvent(renderEvents)

  return (
    <div className="flex-item flex-area in-room-main-content">
      <div className="left-spacer" />
      <LeftPanel event={event} />

      <CenterPanel>
        {event ? <CurrentEvent event={event} ampm={ampm} /> : <Timetable ampm={ampm} events={renderEvents} />}
      </CenterPanel>

      {event && <RightPanel events={renderEvents} ampm={ampm} />}
    </div>
  )
}

export default DisplayBody
