import React from 'react'

import './icon.scss'

type TProps = {
  children: React.ReactNode
}

function Icon(props: TProps): JSX.Element {
  return <span className="InlineSVG">{props.children}</span>
}

export default Icon
