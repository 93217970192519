import React from 'react'
import JoanLogo from '../../../../../assets/img/Joan_icon_white.svg'
import Timestamp from '../../../../components/date/timestamp'

import { formatTime, getTimeNow } from '@joan/joan-core'

import './header.scss'
import { APP_HOST } from '../../../../../api/env.helpers'

type TProps = {
  logo?: string
  ampm: boolean
  roomName: string
}

type TState = {
  date: string
  time: string
}

class DisplayHeader extends React.PureComponent<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      date: DisplayHeader.getDate(),
      time: DisplayHeader.getTime(),
    }
  }

  public componentDidMount(): void {
    setInterval(() => {
      this.setState({
        date: DisplayHeader.getDate(),
        time: DisplayHeader.getTime(),
      })
    }, 1000)
  }

  public render(): JSX.Element {
    return (
      <div className="in-room-header">
        <div className="logo">
          <span className="logo-img">{this.getLogo()}</span>
        </div>
        <div className="room-name">{this.props.roomName}</div>
        <div className="room-datetime">
          <div className="room-date">{this.state.date}</div>
          <div className="room-time">
            <Timestamp time={this.state.time} showAMPM={this.props.ampm} />
          </div>
        </div>
      </div>
    )
  }

  private getLogo(): JSX.Element {
    if (this.props.logo) {
      return <img src={APP_HOST + this.props.logo} alt="Company logo" />
    }

    return <img src={JoanLogo} alt="Default logo" />
  }

  private static getDate(): string {
    return formatTime(DisplayHeader.getCurrentTime(), 'ddd, MMM D, YYYY')
  }

  private static getTime(): string {
    return getTimeNow().toISOString()
  }

  private static getCurrentTime(): any {
    return getTimeNow()
  }
}

export default DisplayHeader
