import React, { Fragment } from 'react'
import DisplayHeader from './header/header'
import DisplayBody from './body/body'

import DisplayFooter from './footer/footer'
import { IEvent } from '../../../../interfaces/events'

type TProps = {
  logo?: string
  roomName: string
  ampm: boolean
  events: IEvent[]
}

function DisplayContainer(props: TProps): JSX.Element {
  return (
    <Fragment>
      <DisplayHeader logo={props.logo} roomName={props.roomName} ampm={props.ampm} />
      <DisplayBody events={props.events} ampm={props.ampm} />
      <DisplayFooter />
    </Fragment>
  )
}

export default DisplayContainer
