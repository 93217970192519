import React from 'react'
import { Redirect } from 'react-router-dom'

import DisplayContainer from './display/container'
import NoRoomWarning from '../../components/noRoomWarning'
import Loading from '../../components/loading'

import { IRoomsState } from '../../../interfaces/reducers/rooms'
import { IStoreState } from '../../../interfaces/store'

import { connect } from 'react-redux'

type TProps = {
  authenticated: boolean
  locale: string
  rooms: IRoomsState
  login: Function
  getRooms: Function
}

class Display extends React.PureComponent<TProps> {
  static defaultProps = {
    authenticated: false,
    locale: 'en',
    rooms: Object(),
  }

  public render(): JSX.Element {
    if (!this.props.authenticated) {
      return <Redirect to="/login" />
    }

    if (!this.props.rooms.roomsInit) {
      return <Loading />
    }

    const { rooms } = this.props

    return (
      <div className="flex-parent in-room-container">
        {!rooms.room ? (
          <NoRoomWarning />
        ) : (
          <DisplayContainer
            logo={rooms.logo}
            ampm={Boolean(rooms.ampm)}
            roomName={rooms.room.roomName}
            events={rooms.room.events}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: IStoreState): Pick<TProps, 'authenticated' | 'locale' | 'rooms'> => ({
  authenticated: state.metadata.authenticated,
  locale: state.metadata.locale,
  rooms: state.rooms,
})

export default connect(mapStateToProps)(Display)
