import { RoomsActionType, IRoomsState } from '../../interfaces/reducers/rooms'
import { ROOMS_LOADED, ROOMS_PAYMENT_REQUIRED } from '../../types/rooms'
import { IRoom } from '../../interfaces/room'
import { IEvent } from '../../interfaces/events'
import { getTime, setLocale } from '@joan/joan-core'

const initialState: IRoomsState = {
  roomsInit: false,
  name: null,
  interface: 0,
  locale: 'en',
  requiredPayment: false,
  customNoWifiImg: false,
  customNoWifiImgHash: '',
  pv2features: 1,
  roomSelect: false,
  rooms: [],

  // Isolated current room. This is used in the
  // interfaces with only one room
  room: undefined,

  timezone: 'UTC',
  ampm: 1,
  featureset: undefined,
  customButtons: undefined,
  customContent: undefined,

  logo: undefined,
  text: undefined,
  sleepUntil: undefined,
}

const sortEvents = (events: IEvent[]): IEvent[] => {
  const currentTime = getTime(new Date().toISOString())

  events = events.filter((event: IEvent): boolean => {
    return getTime(event.end).isAfter(currentTime)
  })

  return events.sort((a: IEvent, b: IEvent): number => {
    const startTimeA = getTime(a.start).toDate()
    const startTimeB = getTime(b.start).toDate()

    const endTimeA = getTime(a.end).toDate()
    const endTimeB = getTime(b.end).toDate()

    const startDiff = startTimeA.getTime() - startTimeB.getTime()
    const endDiff = endTimeA.getTime() - endTimeB.getTime()
    // Name diff will normalize sort when dates are equal.
    const nameDiff = a.summary.localeCompare(b.summary)

    return startDiff + endDiff + nameDiff
  })
}

const mapToRoom = (rooms: IRoom[]): IRoom | undefined => {
  if (rooms.length < 0) {
    return undefined
  }

  const room = rooms[0]

  if (room) {
    room.events = sortEvents(room.events)
  }

  return room
}

function Rooms(state: IRoomsState = initialState, action: RoomsActionType): IRoomsState {
  switch (action.type) {
    case ROOMS_LOADED:
      state = action.data

      setLocale(state.locale)

      // Currently we always map rooms array to one room. This is done
      // because in room interface can have only one room. It's batter to
      // write all mapping logic in reducer (easier to test).
      state.roomsInit = true
      state.room = mapToRoom(action.data.rooms)

      return { ...state, requiredPayment: false }

    case ROOMS_PAYMENT_REQUIRED:
      return { ...state, requiredPayment: true }
  }

  return state
}

export default Rooms
