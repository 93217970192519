import uuid from 'uuid/v4'

type TDefaultValue = string | (() => string | null) | null

const getQueryParam = (name: string, defaultValue: TDefaultValue = null): string | null => {
  const parsedUrl = new URL(window.location.href)

  if (parsedUrl.searchParams.has(name)) {
    return parsedUrl.searchParams.get(name)
  }

  if (defaultValue instanceof Function) {
    return defaultValue()
  }

  return defaultValue
}

const getQueryParamOrLocalStorage = (name: string, defaultValue: TDefaultValue): string | null => {
  const { localStorage } = window
  const QUERY_KEY = name
  const STORAGE_KEY = name.toUpperCase()

  let valueStorage = localStorage.getItem(STORAGE_KEY)
  const valueParam = getQueryParam(QUERY_KEY)

  if (valueParam) {
    valueStorage = valueParam as string
    localStorage.setItem(STORAGE_KEY, valueStorage)
  }

  if (!valueStorage) {
    valueStorage = defaultValue instanceof Function ? defaultValue() : defaultValue
    if (valueStorage !== null) {
      localStorage.setItem(STORAGE_KEY, valueStorage)
    }
  }

  return valueStorage
}

export const getUUID = (): string => {
  return getQueryParamOrLocalStorage('uuid', uuid) as string
}

export const getProvider = (): string | null => {
  // We set the default provider to `OPTOMA` (see JOAN-2175)
  return getQueryParamOrLocalStorage('provider', 'OPTOMA')
}
