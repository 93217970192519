import React from 'react'

import { formatTime, getTime } from '@joan/joan-core'

type TProps = {
  time: string
  showAMPM?: boolean
  showSeconds?: boolean
}

const Timestamp = ({ time, showSeconds = false, showAMPM = true }: TProps): JSX.Element => {
  const getFormattedTime = (time: string, format: string): string => {
    return formatTime(getTime(time), format)
  }

  const hoursAndMinutesFormat = showAMPM === true ? (showSeconds === true ? 'hh:mm' : 'hh:mm A') : 'HH:mm'
  const secondsFormat = showAMPM === true ? ':ss A' : ':ss'

  let className = 'timestamp'

  if (showAMPM) {
    className += ' ampm'
  }

  return (
    <span className={className}>
      <span>{getFormattedTime(time, hoursAndMinutesFormat)}</span>
      {showSeconds && <span className="format-seconds">{getFormattedTime(time, secondsFormat)}</span>}
    </span>
  )
}

export default Timestamp
