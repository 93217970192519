import React from 'react'
import { Trans } from '@joan/joan-core'

type TProps = {
  errorType: string
}

function Error(props: TProps): JSX.Element {
  let errorMsg = 'Unknown error occurred. Please contact Visionect support.'

  if (props.errorType === 'invalid_session') {
    errorMsg = 'Could not pair device on this server. Please remove it from your account and pair it again.'
  }

  return (
    <p>
      <Trans>{errorMsg}</Trans>
    </p>
  )
}

export default Error
