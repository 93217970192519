import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './views/app'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    window.location.reload()
  },
})
