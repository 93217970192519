import React from 'react'
import { IEvent } from '../../../../../../interfaces/events'
import Meeting from './center/meeting'

type TProps = {
  ampm: boolean
  events: IEvent[]
}

function RightPanel(props: TProps): JSX.Element {
  return (
    <div className="right-panel">
      {props.events.map((event: IEvent, index: number) => {
        return (
          <div key={index} className="next-meeting">
            <Meeting
              summary={event.summary}
              start={event.start}
              end={event.end}
              ampm={props.ampm}
              organizer={event.organizer}
              showDate
            />
          </div>
        )
      })}
    </div>
  )
}

export default RightPanel
