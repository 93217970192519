import React, { Fragment } from 'react'
import Timestamp from './timestamp'

type TProps = {
  startTime: string
  endTime: string
  ampm?: boolean
  seconds?: boolean
}

function StartEndHour(props: TProps): JSX.Element {
  return (
    <Fragment>
      <Timestamp time={props.startTime} showAMPM={props.ampm} showSeconds={props.seconds} />
      <span> - </span>
      <Timestamp time={props.endTime} showAMPM={props.ampm} showSeconds={props.seconds} />
    </Fragment>
  )
}

export default StartEndHour
