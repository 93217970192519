import React, { Fragment } from 'react'
import SplashScreen from './splashScreen'
import JoanHeadSvg from '../../assets/img/Joan_icon_white.svg'

import { Trans } from '@joan/joan-core'

function NoRoomWarning(): JSX.Element {
  return (
    <SplashScreen icon={JoanHeadSvg} title={<Trans>Success</Trans>}>
      <Fragment>
        <p>
          <Trans>This screen is now connected to your Joan account.</Trans>
        </p>
        <p>
          <Trans>Set the meeting rooms you want to feature in the Joan Portal, at portal.getjoan.com.</Trans>
        </p>
      </Fragment>
    </SplashScreen>
  )
}

export default NoRoomWarning
