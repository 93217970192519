import React from 'react'

type TProps = {
  className?: string
  icon?: string
  title?: string | React.ReactNode
  children?: JSX.Element
}

function SplashScreen({ className, icon, title, children }: TProps): JSX.Element {
  let classString = 'splash-screen'

  if (className) {
    classString += ' ' + className
  }

  return (
    <div className={classString}>
      <div className="splash-screen-inner">
        {icon ? (
          <div className="splash-screen-icon">
            <img src={icon} alt="" />
          </div>
        ) : null}

        {title ? <h4>{title}</h4> : null}

        {children ? <div className="splash-screen-content">{children}</div> : null}
      </div>
    </div>
  )
}

export default SplashScreen
