import React from 'react'
import JoanHeadSvg from '../../../assets/img/Joan_icon_white.svg'
import Loading from '../../components/loading'
import SplashScreen from '../../components/splashScreen'
import Pin from './login/pin'

import { IStoreState } from '../../../interfaces/store'

import { connect } from 'react-redux'
import metadataActions from '../../../context/actions/metadata'

import { AnyAction } from 'redux'

import { Redirect } from 'react-router-dom'
import Error from './login/error'

type TProps = {
  pin: string | null
  error: string | null
  authenticated: boolean
  login: () => object
}

class Login extends React.PureComponent<TProps> {
  public componentDidMount(): void {
    this.props.login()
  }

  public render(): JSX.Element {
    const { pin, authenticated, error } = this.props

    if (!authenticated && !pin && !error) {
      return <Loading />
    }

    if (authenticated) {
      return <Redirect to="/" />
    }

    return (
      <SplashScreen icon={JoanHeadSvg}>
        {pin ? <Pin pin={pin} /> : error ? <Error errorType={error} /> : <span />}
      </SplashScreen>
    )
  }
}

const mapStateToProps = (state: IStoreState): Pick<TProps, 'pin' | 'authenticated' | 'error'> => ({
  pin: state.metadata.pin,
  authenticated: state.metadata.authenticated,
  error: state.metadata.error,
})

const mapDispatchToProps = (dispatch: Function): object => ({
  login: (): AnyAction => dispatch(metadataActions.login()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
