import React from 'react'
import { langDirection, TransProvider } from '@joan/joan-core'
import { TLocale } from '@joan/joan-core/dist/interfaces/Locale.interface'
import Loading from './loading'
import { connect } from 'react-redux'
import { IStoreState } from '../../interfaces/store'
import PaymentNotification from './paymentNotification'
import { updateLocale } from '../../api/locale.helpers'
import { APP_HOST } from '../../api/env.helpers'

const whitelistInterfaces = [9]

type TProps = {
  children: React.ReactNode
  type: string
  className?: string
  uuid?: string
  authenticated?: boolean
  locale?: string
  interface?: number
  requiredPayment?: boolean
  catalog?: string
  externalsLoaded?: boolean
}

class ContentWrapper extends React.PureComponent<TProps> {
  constructor(props: TProps) {
    super(props)

    this.state = {}
  }

  public static getDerivedStateFromProps(props: TProps): null {
    if (props.authenticated && !whitelistInterfaces.includes(props.interface as number)) {
      window.location.replace(`${APP_HOST}/display/add/?uuid=${props.uuid}`)
    }

    return null
  }

  private translationChange = (lang: TLocale): void => {
    const root = document.getElementsByTagName('html')[0]
    const direction = langDirection(lang)

    if (lang) {
      root.setAttribute('lang', lang)
    }

    if (direction) {
      root.setAttribute('dir', direction)
    }

    updateLocale(lang)

    this.forceUpdate()
  }

  public render(): JSX.Element {
    const id = 'joan-' + this.props.type
    const classNames = [this.props.className || '', this.props.type + '-container']

    return (
      <div id={id} className={classNames.join(' ')}>
        <TransProvider
          lang={this.props.locale || 'en'}
          catalog={this.props.catalog || 'joan'}
          onChange={this.translationChange}
          loader={<Loading />}
          externalsLoaded={this.props.externalsLoaded}
        >
          {this.props.requiredPayment ? <PaymentNotification /> : this.props.children}
        </TransProvider>
      </div>
    )
  }
}

const mapStateToProps = (state: IStoreState): object => ({
  uuid: state.metadata.uuid,
  authenticated: state.metadata.authenticated,
  locale: state.metadata.locale,
  interface: state.metadata.interface,
  requiredPayment: state.rooms.requiredPayment,
})

export default connect(mapStateToProps)(ContentWrapper)
