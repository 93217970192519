import React from 'react'
import { ReactComponent as JoanSVG } from '../../assets/img/Joan_icon_white.svg'
import { Trans } from '@joan/joan-core'

function PaymentNotification(): JSX.Element {
  return (
    <div className="payment-notification">
      <JoanSVG />
      <Trans>Please visit your Joan Portal to resolve this issue!</Trans>
    </div>
  )
}

export default PaymentNotification
