import React, { Fragment } from 'react'
import Timestamp from '../../../../../../components/date/timestamp'
import ProgressBar from '../../../../../../components/date/progressBar'
import { IEvent } from '../../../../../../../interfaces/events'
import { Trans } from '@joan/joan-core'

type TProps = {
  event: IEvent
  ampm: boolean
}

function CurrentEvent({ ampm, event }: TProps): JSX.Element {
  return (
    <Fragment>
      <div className="meeting-title">
        <span className="color-spacer" />
        <h4 className="current-event">
          <Trans>Ongoing meeting</Trans>
        </h4>
        <span />
      </div>
      <div className="flex-item">
        <div className="meeting-description">
          {event.description.split('\n').map(
            (item, key): JSX.Element => {
              return (
                <Fragment key={key}>
                  <p>{item}&nbsp;</p>
                </Fragment>
              )
            },
          )}
        </div>
      </div>
      <div className="meeting-until">
        <Trans>Until</Trans> <Timestamp time={event.end} showAMPM={ampm} />
      </div>
      <ProgressBar startTime={event.start} endTime={event.end} />
    </Fragment>
  )
}

export default CurrentEvent
